import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-form2',
    standalone: true,
    templateUrl: './form2.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})
export class Form2Component 
{
  form2!: FormGroup
  currentPage!: number
  info = faInfoCircle
  exclamation = faExclamation

  constructor
  (
    private navigationService: NavigationService
  )
  {
    window.parent.postMessage('scrollUp', environment.PARENT_URL)

    this.form2 = new FormGroup({
      condition_1 : new FormControl(false,[Validators.requiredTrue]),
      condition_2 : new FormControl(false,[Validators.requiredTrue]),
      condition_3 : new FormControl(false,[Validators.requiredTrue]),
      condition_4 : new FormControl(false,[Validators.requiredTrue]),
      // allConditions : new FormControl(false,[Validators.requiredTrue])
    })
  }

  ngOnInit()
  {
    this.currentPage = this.navigationService.getCurrentPage()
  }

  isInvalid(controlName: string)
  {
    const control = this.form2.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  // readAllConditions()
  // {
  //   let checkboxValue:boolean = this.form2.get("allConditions")?.value

  //   checkboxValue = !checkboxValue
    
  //   if( checkboxValue == true )
  //   {
  //     this.form2.get("condition_1")?.patchValue(true)
  //     this.form2.get("condition_2")?.patchValue(true)
  //     this.form2.get("condition_3")?.patchValue(true)
  //     this.form2.get("condition_4")?.patchValue(true)

  //     this.form2.markAllAsTouched()
  //   }
  //   else
  //   {
  //     this.form2.get("condition_1")?.patchValue(false)
  //     this.form2.get("condition_2")?.patchValue(false)
  //     this.form2.get("condition_3")?.patchValue(false)
  //     this.form2.get("condition_4")?.patchValue(false)
  //   }
  // }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) this.navigationService.redirect(n)

    if (this.form2.invalid) this.form2.markAllAsTouched()
    else this.navigationService.redirect(n)
  }
}
