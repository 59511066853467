import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";

@Component({
    selector: 'app-page-not-found',
    standalone: true,
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.css',
    imports: [CommonModule, StepBarComponent]
})
export class PageNotFoundComponent {
  constructor(private navigationService: NavigationService){}

  returnToStepOne()
  {
    this.navigationService.redirectToRoot()
  }
}
