import { Injectable } from '@angular/core';
import { Form } from '../../class/Form';

@Injectable({
  providedIn: 'root'
})

export class FormDataService 
{
  private formData!: Form

  saveFormData(data: Form)
  {
    this.formData = data
    sessionStorage.setItem("form",JSON.stringify(this.formData))
  }
}
