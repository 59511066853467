import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AutoCompleteService {

  private AUTO_COMPLETE_TYPES:Array<string> = ["cp","cp_correspondance"]
  private readonly HOST:string = environment.BASE_URL
  constructor(
    private httpClient: HttpClient
    ) { }

    async autocomplete(type:string, searchString:string):Promise<Observable<{cp:string, commune:string}[]>>
    {
      if(!this.AUTO_COMPLETE_TYPES.includes(type)) return new Observable<{cp:string, commune:string}[]>()

      const url  = `${this.HOST}/autocomplete`

      return this.httpClient.get<{cp:string, commune:string}[]>(url,
        {
          params:
          {
            type:type,
            data:searchString
          }
        })
    }
}
