import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation, faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';


@Component({
    selector: 'app-form3',
    standalone: true,
    templateUrl: './form3.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})
export class Form3Component 
{
  form3!: FormGroup
  currentPage!: number
  isErrorVisible: boolean = false
  info = faInfoCircle
  exclamation = faExclamation
  file = faFileText

  constructor
  (
    private navigationService: NavigationService,
  )
  {
    this.form3 = new FormGroup({
      documents : new FormControl(false,[Validators.requiredTrue]),
    })
  }

  ngOnInit()
  {
    this.currentPage = this.navigationService.getCurrentPage()
  }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) 
    {
      this.navigationService.redirect(n)
      return
    }

    if (this.form3.invalid)
    {
      this.isErrorVisible = true
      return
    }
    else
    {
      this.isErrorVisible = false
      this.navigationService.redirect(n)
    }
  }
}
