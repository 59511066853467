import { environment } from "../../../environments/environment";

export function ScrollToTop() 
{
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) 
    {
      const originalMethod = descriptor.value; // Store the original method
  
      descriptor.value = function (...args: any[]) 
      {
        // Call the original method
        const result = originalMethod.apply(this, args)

        window.scrollTo(0, 0)
        window.parent.postMessage('scrollUp', environment.PARENT_URL)
        
        return result// Return the result of the original method
      }
  
      return descriptor
    }
  }
  