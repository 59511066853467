import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { Form } from '../class/Form';

@Component({
  selector: 'app-form',
  standalone: true,
  templateUrl: './form.component.html',
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
})

export class FormComponent
{
  constructor() 
  {
    window.scrollTo(0, 0)
  }
}

const formFromStorage = sessionStorage.getItem("form")
let saisine:Form

if (formFromStorage != null && formFromStorage !== "") 
{
  try 
  {
    saisine = JSON.parse(formFromStorage)
  } 
  catch (error) 
  {
    console.error("Failed to parse form from sessionStorage:", error)
    saisine = new Form()
  }
} 
else 
{
    saisine = new Form()
}

const file_list: { [key: string]: File[] } = 
{
  file1_list: [],
  file2_list: [],
  file3_list: [],
  file4_list: [],
  file5_list: [],
  file6_list: []
}

export {saisine, file_list}
