import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UploadService 
{

  constructor(private httpClient:HttpClient) { }

  async uploadFile(file:FormData):Promise<Observable<any>>
  {
    const HOST = environment.BASE_URL
    const url  = `${HOST}/upload`

    return this.httpClient.post(url, file)
  }
}
