import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpersService 
{
  private readonly HOST:string = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
    ) {}

    async getListLienAbonne():Promise<Observable<{ID_TYPE_REPRESENTANT:number, TYPE_REPRESENTANT:string, ORDRE:number}[]>>
    {
      const url  = `${this.HOST}/liste_lien_abo`

      return this.httpClient.get<{ID_TYPE_REPRESENTANT:number, TYPE_REPRESENTANT:string, ORDRE:number}[]>(url)
    }

    checkIdUser(id_user:number):Observable<{ID_UTILISATEUR:string}[]>
    {
      const url  = `${this.HOST}/check_id`

      return this.httpClient.get<{ID_UTILISATEUR:string}[]>(url,
        {
          params:
          {
            id_user
          }
        }
      )
    }
}
