<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 6 </h4>
        <hr>

        <h4>
            Les pièces du dossier
        </h4>

        <h5>
            Merci de transmettre les documents indispensables à l’analyse du dossier
        </h5>

        <h5>
            Les documents ne seront enregistrés qu'à la validation du formulaire de saisine (Étape 7)
        </h5>

        <p>
            <b>Seuls les formats pdf, jpg, jpeg et png ne dépassant pas 10 Mo sont acceptés</b>
        </p>
    </div>
</div>

<div class="tab tab6 d-flex flex-column" [formGroup]="form6">
    <div class="content">
        <div class="content-left">
            <p><span class="obligatoire bold">*</span> Documents obligatoires</p>

            <div>
                <p>
                    <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon> 
                    Copie des réclamations écrites adressées aux services d’eau et/ou d’assainissement  &nbsp;
                    <span class="obligatoire bold"> *</span>
                </p>
    
                <div>
                    <div class="d-flex">
                        <input type="file" id="file1" class="file1 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(1, $event)" multiple>
                        <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer tous les éléments de cette liste" (click)="removeFileContainer(1)" *ngIf="form6.get('file1')?.value"></fa-icon> -->
                    </div>
                    <em *ngIf="isInvalid('file1') && form6.get('file1')?.errors">
                        <p>* Vous devez fournir un fichier</p>
                    </em>
                </div>
            </div>

            <div class="my-2 row" *ngFor="let item of fileList['file1_list'], let i = index">
                <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                <div class="col-4">
                    <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(1, i)" *ngIf="form6.get('file1')?.value"></fa-icon>
                </div>
            </div>

            <hr>
    
            <div>
                <p>
                    <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon>
                    Copie <u>recto-verso</u> des factures d’eau et/ou d’assainissement (ou du devis) afin d'identifier le(s) service(s) &nbsp;
                    <span class="obligatoire bold"> *</span>
                </p>
    
                <label for="doc1" class="orange-label cursor d-flex align-items-center h6" (click)="activateCheckbox(2)">
                    <input class="mr-3" type="checkbox" id="checkbox2" name="checkbox2" formControlName="checkbox2">
                    Je confirme ne pas disposer de ce(s) document(s)
                </label>
    
                <div>
                    <div class="d-flex">
                        <input type="file" id="file2" name="file2" class="file2 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(2, $event)" *ngIf="!form6.get('checkbox2')?.value" multiple>
                        <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer" (click)="removeFileContainer(2)" *ngIf="form6.get('file2')?.value"></fa-icon> -->
                    </div>
                    <em *ngIf="form6.get('checkbox2')?.value === false && isInvalid('file2') && form6.get('file2')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
            </div>

            <div class="my-2 row" *ngFor="let item of fileList['file2_list'], let i = index">
                <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                <div class="col-4">
                    <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(2, i)" *ngIf="form6.get('file2')?.value"></fa-icon>
                </div>
            </div>

            <hr>
    
            <div>
                <p>
                    <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon> 
                    Copie des réponses des services &nbsp;
                    <span class="obligatoire bold"> *</span>
                </p>
    
                <label for="doc3" class="orange-label cursor d-flex align-items-center h6" (click)="activateCheckbox(3)">
                    <input class="mr-3" type="checkbox" id="checkbox3" name="checkbox3" formControlName="checkbox3">
                    Je confirme ne pas disposer de ce(s) document(s)
                </label>
    
                <div>
                    <div class="d-flex">
                        <input type="file" id="file3" name="file3" class="file3 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(3, $event)" *ngIf="!form6.get('checkbox3')?.value" multiple>
                        <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer" (click)="removeFileContainer(3)" *ngIf="form6.get('file3')?.value"></fa-icon>  -->
                    </div>
                    <em *ngIf="form6.get('checkbox3')?.value === false &&  isInvalid('file3') && form6.get('file3')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
            </div>


            <div class="my-2 row" *ngFor="let item of fileList['file3_list'], let i = index">
                <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                <div class="col-4">
                    <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(3, i)" *ngIf="form6.get('file3')?.value"></fa-icon>
                </div>
            </div>

            <hr>

                        
            <div *ngIf="list_mandat.includes(lien_abo_id)">
                <div>
                    <p>
                        <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon>  
                        Justificatif du représentant (mandat, pouvoir, acte officiel, etc.) &nbsp;
                        <span class="obligatoire bold"> *</span>
                    </p>
        
                    <div>
                        <div class="d-flex">
                            <input type="file" id="file6" name="file6" class="file6 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(6, $event)" multiple>
                            <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer" (click)="removeFileContainer(6)" *ngIf="form6.get('file6')?.value"></fa-icon> -->
                        </div>
                    </div>
                </div>
                

                <div class="my-2 row" *ngFor="let item of fileList['file6_list'], let i = index">
                    <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                    <div class="col-4">
                        <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(6, i)" *ngIf="form6.get('file6')?.value"></fa-icon>
                    </div>
                </div>

                <hr> 	

                <em *ngIf="list_mandat.includes(lien_abo_id) && isInvalid('file6') && form6.get('file6')?.errors">
                    <p>* Champ obligatoire</p>
                </em>
            </div>
    
            <div>
                <p>
                    <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon>  
                    En cas de fuite, indiquer son emplacement précis et transmettre la copie de facture de réparation le cas échéant
                </p>
    
                <div>
                    <div class="d-flex">
                        <input type="file" id="file4" name="file4" class="file4 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(4, $event)" multiple>
                        <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer" (click)="removeFileContainer(4)" *ngIf="form6.get('file4')?.value"></fa-icon>  -->
                    </div>
                </div>

                <div class="my-2 row" *ngFor="let item of fileList['file4_list'], let i = index">
                    <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                    <div class="col-4">
                        <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(4, i)" *ngIf="form6.get('file4')?.value"></fa-icon>
                    </div>
                </div>
            </div>
            
             <br>

            <div>
                <div 
                    class="charNum"
                    id="countExplic"
                    [ngClass]="form6.get('explication_doc4')?.value?.length >= limit ? 'text-bold text-danger': ''">
                    {{form6.get('explication_doc4')?.value?.length}} caractère@if(form6.get('explication_doc4')?.value?.length > 1){s} / {{limit}} caractères 
                </div>

                <textarea 
                    class="mt-2" 
                    name="doc4" 
                    id="explication_doc4" 
                    placeholder="explications...(300 caractères maximum)" 
                    [maxLength]="limit" 
                    formControlName="explication_doc4"
                    [ngClass]="form6.get('explication_doc4')?.value?.length >= limit ? 'bg-red': ''"
                    ></textarea>
            </div>
    
            <hr>
    
            <div>
                <p>
                    <fa-icon [icon]="file" size="sm" class="fa-icon-file"></fa-icon>  
                    Autres documents
                </p>
    
                <div>
                    <div class="d-flex">
                        <input type="file" id="file5" name="file5" class="file5 file form-control p-0" accept=".jpeg,.jpg,.pdf,.png" (change)="onChangeFile(5, $event)" multiple>
                        <!-- <fa-icon [icon]="close" class="fa-icon-close" size="xl" title="Supprimer" (click)="removeFileContainer(5)" *ngIf="form6.get('file5')?.value"></fa-icon> -->
                    </div>
                </div>
            </div>

            <div class="my-2 row" *ngFor="let item of fileList['file5_list'], let i = index">
                <div class="col-8">{{item.name + "&nbsp; &nbsp;"}}</div>
                <div class="col-4">
                    <fa-icon [icon]="close" class="fa-icon-close" size="sm" title="Supprimer ce document" (click)="removeOneFile(5, i)" *ngIf="form6.get('file5')?.value"></fa-icon>
                </div>
            </div>
    
            <em *ngIf="isValid === false" id="alertMsg6">
                <p>* Merci de vérifier les documents obligatoires</p>
            </em> 
        </div>
    </div>
    

    
    <div class="nav-buttons align-self-center" id="buttons">
        <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
        <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
    </div>
</div>
