import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepBarComponent } from '../step-bar/step-bar.component';
import { NavigationService } from '../services/navigation/navigation.service';

@Component({
  selector: 'app-form8',
  standalone: true,
  templateUrl: './form8.component.html',
  imports: [CommonModule, StepBarComponent]
})
export class Form8Component 
{
  currentPage!: number

  constructor(
    private navigationService: NavigationService
  ){}

  ngOnInit()
  {
    window.scrollTo(0, 0)
    this.currentPage = this.navigationService.getCurrentPage()
  }
}
