<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 1 </h4>
        <hr>

        <h4>
            La recevabilité de la saisine : les exclusions
        </h4>
        
        <p>
            Assurez-vous que le litige entre bien dans le champ de compétence du Médiateur de l'eau. A défaut, le dossier ne sera pas recevable pour une médiation
        </p>

        <p>
            Le litige doit être relatif à l'exécution du service public de l'eau et/ou de l'assainissement. En sont notamment exclus les litiges ci-dessous :
        </p>
    </div>
</div>

<div class="tab tab1 d-flex flex-column" [formGroup]="form1">
    <div class="content">
        <div class="content-left">   
            <ul>
                <li>
                    La facturation de l'eau potable inclue dans les charges locatives ou de copropriété
                </li>
            
                <li>
                    Les demandes relatives aux décisions prises par la collectivité par délibération (ex : les tarifs de l'eau) 
                    <span class="obligatoire"><strong class="h4">*</strong></span>
                </li>
            
                <li>
                    Les litiges qui doivent être pris en charge par votre assurance (ex : suite à un dégât des eaux)
                </li>
            
                <li>
                    Les litiges privés (ex : rapports entre propriétaires et locataires ou entre voisins)
                </li>
            
                <li>
                    Les prestations contractées directement avec une entreprise (ex : entretien ou relève des compteurs divisionnaires)
                </li>
            
                <li>
                    Les demandes d'aide financière (ex : échéanciers de paiement, modification de la mensualisation, etc.)
                </li>
            
                <li>
                    Autres cas : contestation des résultats du contrôle Assainissement Non Collectif 
                    <span class="obligatoire"><strong class="h4">*</strong></span>, 
                    refus par l'exploitant de raccordement au réseau d'eau potable ou eaux usées 
                    <span class="obligatoire"><strong class="h4">*</strong></span>, litige recouvrement avec la trésorerie, etc.
                </li>
            
            </ul>
            
            <hr />

            <p>Concernant les litiges suivis d'un <span class="text-danger font-weight-bold h4">*</span>, vous pouvez transmettre votre dossier au Défenseur des Droits via son site internet :  
                <a class="h4" target="_blank" href="https://www.defenseurdesdroits.fr/">www.defenseurdesdroits.fr</a>
            </p>

            <br>
    
            <label class="checkbox-container bold" for="input0">
                <input type="checkbox" id="input0" name="input0" formControlName="exclusions">
                J'ai bien lu les exclusions. Je ne suis dans aucun des cas listés ci-dessus.
                <br>
                <em *ngIf="isErrorVisible">
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon>
                        Si vous faites partie d'un cas ci-dessus, votre saisine n'est pas recevable.
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
    </div>

<div class="nav-buttons align-self-center" id="buttons">
    <button class="invisible" type="button" id="prevBtn" [disabled] = true>Précédent</button>
    <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
</div>