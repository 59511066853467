import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepBarComponent } from '../step-bar/step-bar.component';
import { NavigationService } from '../services/navigation/navigation.service';
import { saisine, file_list } from '../form/form.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-form8',
  standalone: true,
  templateUrl: './form8.component.html',
  imports: [CommonModule, StepBarComponent]
})
export class Form8Component 
{
  currentPage!: number
  new_saisine_abonne:boolean = saisine['_new_saisine_abonne']
  lien_espace_abonne = environment.LIEN_ESPACE_ABONNE

  constructor(
    private navigationService: NavigationService
  ){}

  ngOnInit()
  {
    window.scrollTo(0, 0)
    this.currentPage = this.navigationService.getCurrentPage()
    sessionStorage.removeItem("form")

    setTimeout(() => {
      sessionStorage.setItem("new", "")
    }, 1000 * 30)
  }
}
