import { AbstractControl, ValidatorFn } from '@angular/forms';

export function zipValidator(): ValidatorFn 
{
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value

    if (!value || typeof value !== 'string' || value.trim() === '') 
    {
      return { required: true }  // Le code postal est vide ou ne contient que des espaces
    }

    const valid = /^[0-9]{5}$|^(BP|CS|SP|CEDEX) \d{5}$/.test(value)
    return valid ? null : { invalidPostalCode: { value: value } }
  }
}
