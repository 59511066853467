import { Routes } from '@angular/router';
import { FormComponent } from './form/form.component';
import { Form1Component } from './form1/form1.component';
import { Form2Component } from './form2/form2.component';
import { Form3Component } from './form3/form3.component';
import { Form4Component } from './form4/form4.component';
import { Form5Component } from './form5/form5.component';
import { Form6Component } from './form6/form6.component';
import { Form7Component } from './form7/form7.component';
import { Form8Component } from './form8/form8.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const routes: Routes = [
    { path: '', redirectTo: 'formulaire_saisine/etape_1', pathMatch: 'full'},
    {
      path: 'formulaire_saisine', component: FormComponent,
      children: [
        { path: 'etape_1', component: Form1Component },
        { path: 'etape_2', component: Form2Component },
        { path: 'etape_3', component: Form3Component },
        { path: 'etape_4', component: Form4Component },
        { path: 'etape_5', component: Form5Component },
        { path: 'etape_6', component: Form6Component },
        { path: 'etape_7', component: Form7Component },
        { path: 'etape_8', component: Form8Component },
      ]
    },
    { path: 'not-found', component: PageNotFoundComponent },
    { path: '**', redirectTo: 'formulaire_saisine/etape_1', pathMatch: 'full'}
  ];
