<app-step-bar [step]="currentPage"></app-step-bar>
<div class="tab tab7 d-flex flex-column">
    <div class="content">
        <div>
            <div class="content-right">
                <div class="encart" id="info_droite">
                    <h4 class="encart-title">Étape 7 </h4>

                    <hr>
            
                    <h4>
                        Finalisation du dépôt du dossier
                    </h4>
                    
                    <p>
                        Dès la finalisation de votre demande, vous recevrez un mail de confirmation de création de votre compte permettant d’activer votre espace privé
                    </p>

                    <p>
                        Tous les échanges ainsi que la transmission des documents s’effectueront exclusivement via votre espace privé 
                    </p>
                </div>
            </div>
    
            <br>

            <div id="result" class="row mx-5">
                <div class="col-12 h4">Récapitulatif de l'étape 4</div>

                <div class="d-flex flex-column col-12">

                    <div class="d-flex">
                        <div class="h5 col-6 p-0">
                            <p>Vous êtes :
                                @if (lien_abo != 0) 
                                {
                                    Le représentant
                                }
                                @else 
                                {
                                    L'abonné(e)
                                }
                            </p> 

                            <b> Civilité : </b>
                            <span class="text-break">{{civilite_string}}</span>
                            <br>
                            <b>Nom : </b> 
                            <span class="text-break">{{nom}}</span>
                            <br>
                            <b> Prénom : </b>
                            <span class="text-break">{{prenom}}</span>
                            <br>
                            <b> Email : </b>
                            <span class="text-break">{{email}}</span>
                            <br>
        
                            <div *ngIf="societe_organisme">
                                <b> Société ou organisme : </b>
                                <span class="text-break">{{societe_organisme}}</span>
                            </div>
                        </div>
    
                        <div class="h5 col-6">
                            @if(nom_abo)
                            {
                                <p>Informations de l'abonné(e) :</p> 
        
                                <b *ngIf="lien_abo_string"> Lien avec abonné(e) : </b>
                                <span class="text-break">{{ lien_abo_string }}</span>
                                <br>
                                <b *ngIf="civilite_abo_string"> Civilité : </b>
                                <span class="text-break">{{civilite_abo_string}}</span>
                                <br>
                                <b *ngIf="nom_abo"> Nom de l'abonné(e) : </b>
                                <span class="text-break">{{nom_abo}}</span>
                                <br>
                                <b *ngIf="prenom_abo"> Prénom de l'abonné(e) : </b>
                                <span class="text-break">{{prenom_abo}}</span>
                                <br>
                                <b *ngIf="email_abo"> Email de l'abonné(e) : </b> 
                                <span class="text-break">{{ email_abo }}</span>
                                <br>
                                <b *ngIf="societe_organisme_abo"> Société : </b>
                                <span class="text-break">{{societe_organisme_abo}}</span>
                                <br>
                                <b *ngIf="ref_dossier"> Référence dossier: </b>
                                <span class="text-break">{{ref_dossier}}</span>
                            }
                            @else
                            {
                                <span class="orange">Vous n'avez pas déclaré avoir de représentant</span>
                            }
                        </div>
                    </div>
                </div>


                <div class="col-12"><br></div>

                <div class="col-12 h5">
                    <b *ngIf="type_contrat"> Type de contrat : </b>
                    <span class="text-break">{{ type_contrat_string }}</span>
                    <br>

                    <div class="h5" *ngIf="info_eau_potable">
                        <b>Raison facturation eau potable : </b>
                        <p class="break-long-text">{{ info_eau_potable }}</p>
                    </div>

                    <div class="h5" *ngIf="info_assainissement_collectif">
                        <br>
                        <b>Raison facturation assainissement collectif : </b>
                        <p class="break-long-text">{{ info_assainissement_collectif }}</p>
                    </div>
                </div>

                <div class="col-12"><hr></div>

                <div class="col-12 h4">Récapitulatif de l'étape 5</div>

                <div class="col-12"><br></div>

                <div class="col-12 h5">
                    <b> Adresse du litige : </b>
                    <span class="text-break">{{adresse}}</span>
                    <br>
                    <b> Code postal: </b>
                    <span class="text-break">{{cp}}</span> 
                    <br>
                    <b> Commune: </b>
                    <span class="text-break">{{ville}}</span>
                </div>

                <div class="col-12 h5" *ngIf="adresse_correspondance">
                    <br>

                    <b *ngIf="adresse_correspondance"> Adresse de correspondance : </b>
                    <span *ngIf="adresse_correspondance">{{adresse_correspondance}}</span>
                    <br>

                    <b *ngIf="cp_correspondance"> Code postal : </b>
                    <span *ngIf="cp_correspondance">{{cp_correspondance}}</span>
                    <br>

                    <b *ngIf="ville_correspondance"> Commune : </b>
                    <span *ngIf="ville_correspondance">{{ville_correspondance}}</span>
                    <br>

                    <b *ngIf="complement_adresse_correspondance"> Adresse de correspondance si hors de France : </b>
                    <p class="break-long-text" *ngIf="complement_adresse_correspondance">
                        {{complement_adresse_correspondance}} 
                        <br>
                        <span *ngIf="pays_correspondance_string">{{pays_correspondance_string.toUpperCase()}}</span>
                    </p>
                </div>

                <div class="col-12"><br></div>

                <div class="col-12">
                    <b> Description du litige : </b>
                    <p class="break-long-text">
                        {{litige}}
                    </p>
                </div>

                <div class="col-12">
                    <b> Vos attentes : </b>
                    <p class="break-long-text">
                        {{attentes}}
                    </p>
                </div>

                <div class="col-12"><hr></div>

                <div class="col-12 h4">Récapitulatif de l'étape 6</div>

                <div class="col-12">
                    <br>
                    <b>
                        Copie des réclamations écrites adressées aux services d’eau et/ou d’assainissement :
                    </b>
                    <div *ngFor="let item of file1_list">
                        {{item.name}}
                    </div>

                    <hr>

                    <b>
                        Copie recto-verso des factures d’eau et/ou d’assainissement (ou du devis) afin d'identifier le(s) service(s) :
                    </b>
                    @if (file2_list.length > 0) 
                    {
                        <div *ngFor="let item of file2_list">
                            {{item.name}}
                        </div>
                    }
                    @else
                    {
                        <div class="orange">Vous avez indiqué ne pas disposer de ce(s) document(s)</div>
                    }

                    <hr>

                    <b>
                        Copie des réponses des services :
                    </b>
                    @if (file3_list.length > 0) 
                    {
                        <div *ngFor="let item of file3_list">
                            {{item.name}}
                        </div>
                    }
                    @else
                    {
                        <div class="orange">Vous avez indiqué ne pas disposer de ce(s) document(s)</div>
                    }

                    <hr>

                    <b>
                        Copie de la facture de réparation :
                    </b>
                    @if (file4_list.length > 0) 
                    {
                        <div *ngFor="let item of file4_list">
                            {{item.name}}
                        </div>
                    }
                    @else
                    {
                        <div>Pas de documents fournis</div>
                    }

                    <div *ngIf="explication && explication.length > 0">
                        <br>
                        
                        <b>
                            Explication :
                        </b>
                        <p class="break-long-text">
                            {{explication}}
                        </p>
                    </div>


                    <div *ngIf="file6_list.length > 0">
                        <hr>
    
                        <b>
                            Justificatif du représentant  :
                        </b>
                        <div *ngFor="let item of file6_list">
                            {{item.name}}
                        </div>
                    </div>

                    <hr>

                    <b>
                        Autres documents  :
                    </b>

                    @if (file5_list.length > 0) 
                    {
                        <div *ngFor="let item of file5_list">
                            {{item.name}}
                        </div>
                    }
                    @else
                    {
                        <div>Pas de documents fournis</div>
                    }

                </div>
            </div>
        </div>
    </div>
</div>

<div class="nav-buttons align-self-center" id="buttons">
    <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
    <input type="button" value="Je valide ma saisine" id="submitBtn" (click)="saveFormAndNavigate(1)" *ngIf="!isSubmitted"/>
</div>