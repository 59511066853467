import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FormComponent } from "./form/form.component";
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './../styles.css',
    imports: [CommonModule, HttpClientModule,  RouterOutlet, RouterLink, RouterLinkActive, FormComponent, MatButtonModule, MatSnackBarModule, MatDialogModule]
})

export class AppComponent 
{
  title = 'formulaire de saisine'
}
