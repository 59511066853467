import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { Form } from '../../interfaces/form';
import { environment } from '../../../environments/environment';
import { Form } from '../../class/Form';


@Injectable({
  providedIn: 'root'
})

export class FormService 
{
  private readonly HOST:string = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  async sendForm(form:Form):Promise<Observable<Object>>
  {
    const url  = `${this.HOST}/form`

    return this.httpClient.post(url, form)
  }

  async getCivilite(idCivilite:number):Promise<Observable<{CIVILITE:string}[]>>
  {
    const url  = `${this.HOST}/civilite`

    return this.httpClient.get<{CIVILITE:string}[]>(url, 
      {
        params : 
        {
          idCivilite:idCivilite
        }
      })
  }

  async getLienAbo(idLienAbo:number):Promise<Observable<{TYPE_REPRESENTANT:string}[]>>
  {
    const url  = `${this.HOST}/lien_abo`

    return this.httpClient.get<{TYPE_REPRESENTANT:string}[]>(url, 
      {
        params : 
        {
          idLienAbo:idLienAbo
        }
      })
  }

  async getTypeContrat(idTypeContrat:number):Promise<Observable<{TYPE_CONTRAT:string}[]>>
  {
    const url  = `${this.HOST}/type_contrat`

    return this.httpClient.get<{TYPE_CONTRAT:string}[]>(url, 
      {
        params : 
        {
          idTypeContrat:idTypeContrat
        }
      })
  }

  async getPays(idPays:number):Promise<Observable<{NOM_PAYS:string}[]>>
  {
    const url  = `${this.HOST}/pays_id`

    return this.httpClient.get<{NOM_PAYS:string}[]>(url, 
      {
        params : 
        {
          idPays
        }
      })
  }

}
