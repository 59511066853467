import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { FormService } from '../services/form/form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from '../services/upload/upload.service';
import { MailService } from '../services/mail/mail.service';
import { saisine, file_list } from '../form/form.component';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';


@Component({
    selector: 'app-form7',
    standalone: true,
    templateUrl: './form7.component.html',
    imports: [CommonModule, StepBarComponent]
})

export class Form7Component 
{
  date:Date = new Date()
  localeDateString = this.date.toLocaleDateString().replaceAll("/","-")
  isValid!: boolean
  civilite!: number
  civilite_string!:string
  nom!: string
  prenom!: string
  email!: string
  societe_organisme!:string
  type_contrat!: number
  type_contrat_string!: string
  info_eau_potable!: string
  info_assainissement_collectif!: string
  civilite_abo!: number
  civilite_abo_string!:string
  lien_abo!: number
  lien_abo_string!:string
  nom_abo!: string
  prenom_abo!: string
  email_abo!: string
  societe_organisme_abo!: string
  ref_dossier!: string
  litige!:string
  attentes!:string
  adresse!:string
  cp!:string
  ville!:string
  pays!:number
  adresse_correspondance!:string
  cp_correspondance!:string
  ville_correspondance!:string
  pays_correspondance!:number
  pays_correspondance_string!:string
  complement_adresse_correspondance!:string
  explication!:string
  file1_list: File[] = []
  file2_list: File[] = []
  file3_list: File[] = []
  file4_list: File[] = []
  file5_list: File[] = []
  file6_list: File[] = []
  currentPage!: number
  isSubmitted:boolean = false

  constructor
  (
    private formService: FormService,
    private navigationService: NavigationService,
    private uploadService: UploadService,
    private mailService: MailService,
    private snackBar: MatSnackBar
  )
  {
    this.alertRedirect()
  }

  async ngOnInit()
  {    
    this.nom = removeHTMLTags(saisine["_nom"])
    this.prenom = removeHTMLTags(saisine["_prenom"])
    this.email = removeHTMLTags(saisine["_email"])
    this.societe_organisme =  removeHTMLTags(saisine["_societe_organisme"])
    this.civilite = saisine["_civilite"]
    
    this.civilite_abo = saisine["_civilite_abo"]
    this.lien_abo = saisine["_lien_abo"]
    this.nom_abo = removeHTMLTags(saisine["_nom_abo"])
    this.prenom_abo = removeHTMLTags(saisine["_prenom_abo"])
    this.email_abo = removeHTMLTags(saisine["_email_abo"])
    this.societe_organisme_abo = removeHTMLTags(saisine["_societe_organisme_abo"])
    this.ref_dossier = removeHTMLTags(saisine["_ref_dossier"])

    this.type_contrat = saisine["_type_contrat"]
    this.info_eau_potable = removeHTMLTags(saisine["_info_eau_potable"])
    this.info_assainissement_collectif = removeHTMLTags(saisine["_info_assainissement_collectif"])

    this.litige = removeHTMLTags(saisine["_litige"])
    this.attentes = removeHTMLTags(saisine["_attentes"])

    this.adresse = removeHTMLTags(saisine["_adresse"])
    this.cp = removeHTMLTags(saisine["_cp"])
    this.ville = removeHTMLTags(saisine["_ville"])
    this.pays = saisine["_pays"]

    this.adresse_correspondance = removeHTMLTags(saisine["_adresse_correspondance"])
    this.cp_correspondance = removeHTMLTags(saisine["_cp_correspondance"])
    this.ville_correspondance = removeHTMLTags(saisine["_ville_correspondance"])
    this.pays_correspondance = saisine["_pays_correspondance"]
    this.complement_adresse_correspondance = removeHTMLTags(saisine["_complement_adresse_correspondance"])

    this.explication = removeHTMLTags(saisine["_explication"])

    this.file1_list = file_list["file1_list"]
    this.file2_list = file_list["file2_list"]
    this.file3_list = file_list["file3_list"]
    this.file4_list = file_list["file4_list"]
    this.file5_list = file_list["file5_list"]
    this.file6_list = file_list["file6_list"]

    await this.getDataCivilite(this.civilite)
    await this.getDataCiviliteAbo(this.civilite_abo)
    await this.getDataLienAbo(this.lien_abo)
    await this.getDataTypecontrat(this.type_contrat)
    await this.getDataPays(this.pays_correspondance)

    this.currentPage = this.navigationService.getCurrentPage()
  }

  async getDataCivilite(id_civilite:number)
  {
    (await this.formService.getCivilite(id_civilite)).subscribe(data => {
      this.civilite_string = data[0]?.CIVILITE
    })
  }
  
  async getDataCiviliteAbo(id_civilite:number)
  {
    (await this.formService.getCivilite(id_civilite)).subscribe(data => {
      this.civilite_abo_string = data[0]?.CIVILITE
    })
  }

  async getDataLienAbo(id_lien_abo:number)
  {
    if( id_lien_abo != 0 )
    {
      (await this.formService.getLienAbo(id_lien_abo)).subscribe(data => {
        this.lien_abo_string = data[0]?.TYPE_REPRESENTANT
      })
    }
  }

  async getDataTypecontrat(id_type_contrat:number)
  {
    if( id_type_contrat != 0 )
    {
      (await this.formService.getTypeContrat(id_type_contrat)).subscribe(data => {
        this.type_contrat_string = data[0]?.TYPE_CONTRAT
      })
    }
  }

  async getDataPays(id_pays:number)
  {
    if( id_pays != 0 && id_pays != 1 )
    {
      (await this.formService.getPays(id_pays)).subscribe(data => {
        this.pays_correspondance_string = data[0]?.NOM_PAYS
      })
    }
  }

  alertRedirect()
  {
    if(
      file_list["file1_list"].length == 0
      && file_list["file2_list"].length == 0
      && file_list["file3_list"].length == 0
      && file_list["file4_list"].length == 0
      && file_list["file5_list"].length == 0
    )
    {
      alert("Vous allez être redirigé à l'étape précédente pour joindre les documents demandés")
      this.navigationService.redirect(-1)
    }
  }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) 
    {
      this.navigationService.redirect(n)
      return
    }

    this.alertRedirect()

    this.submitForm()
  }

  async submitForm()
  {
    const formData = new FormData()
    
    const form = saisine

    const array_files = [];

    (file_list["file1_list"]as any).contenu = "copie reclamation";
    (file_list["file2_list"]as any).contenu = "copie factures";
    (file_list["file3_list"]as any).contenu = "reponse service";
    (file_list["file4_list"]as any).contenu = "indication fuite";
    (file_list["file5_list"]as any).contenu = "autres documents";
    (file_list["file6_list"]as any).contenu = "justificatif representant";

    array_files.push(file_list["file1_list"])
    array_files.push(file_list["file2_list"])
    array_files.push(file_list["file3_list"])
    array_files.push(file_list["file4_list"])
    array_files.push(file_list["file5_list"])
    array_files.push(file_list["file6_list"])


    array_files.map((file_list:any, index:number) => {
      if( file_list.length > 0 )
      {
        const contenu = file_list.contenu

        file_list.forEach((e: string | Blob) => {
          formData.append( `files_${index}`, e )
        })
        formData.append( `contenu_${index}`, contenu )
      }
    })


    // Test Upload
    // formData.append("path", "2024-9999")
    // formData.append("id_user", "74109")
    // formData.append("id_dossier", "37730")
    // ;(await
    //   this.uploadService.uploadFile(formData)).subscribe((dataUploadService) => {
    //   console.log(dataUploadService)
    // })
    // return
    // Fin Test Upload


    ;(await
      this.formService.sendForm(form)).subscribe({
      next: async (dataFormService:any) => {        
        if(!dataFormService.folderName) 
        {
          alert("Un problème est survenu lors de l'upload des fichiers")
          return
        }
        
        formData.append("path", dataFormService.folderName)
        formData.append("id_user", dataFormService.userId)
        formData.append("id_dossier", dataFormService.dossierId)

        ;(await this.uploadService.uploadFile(formData)).subscribe(data => {
          // console.log(data)
        })

        this.isSubmitted = true
        
        sessionStorage.clear()

        this.navigationService.redirect(1)
      }, 
      error: (e) => 
      {    
        console.log(e)

        this.snackBar.open("Un problème est survenu lors de l'envoi du formulaire", "J'ai compris", {
          duration: 5000,
        })
      }
    })
  }

  async sendMail(email_object:object)
  {
    (await this.mailService.sendRequest(email_object))
    .subscribe({
      next: (data) => {
        this.snackBar.open("Email envoyé", "Ok", {
          duration: 5000,
        })
      },
      error: (e:Error) => {
        console.log(e)

        this.snackBar.open(e.message, "J'ai compris", {
          duration: 5000,
        })
      }
    })
  }
}
