<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 5 </h4>
        <hr>

        <h4>
            Le litige
        </h4>

        <p>Exposer clairement le litige (résumé, dates, demandes précises, etc.), cela permettra au Médiateur de mieux appréhender votre différend </p>
    </div>
</div>

<div class="tab tab5" [formGroup]="form5">
    <div class="content">
        <div class="content-left">
            <p><span class="obligatoire bold">*</span> Champs obligatoires</p>

            <br>

                
            <div class="max-width600px">
                <hr>
                
                <div>
                    <div class ="margin8px">
                        <label for="adresse_litige">Adresse du litige :<span class="obligatoire bold"> *</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="adresse_litige" 
                                id="adresse_litige" 
                                class="input_obligatoire" 
                                formControlName="adresse"/>
                        </div>
                        <em *ngIf="isInvalid('adresse') && (form5.get('adresse')?.value?.length == 0 || !form5.get('adresse')?.value)">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('adresse') && form5.get('adresse')?.errors?.['invalidAddress']">
                            <p>* L'adresse saisie n'est pas au bon format</p>
                        </em>
                    </div>
        
                    <div class ="margin8px">
                        <label for="code_postal">Code postal :<span class="obligatoire bold"> *</span></label>
                        <div>
                            <input 
                                type="text" 
                                matInput 
                                formControlName="cp" 
                                [matAutocomplete]="auto"
                                [maxlength]="inputLimit.get('cp')"
                                [ngClass]="form5.get('cp')?.value.length >= inputLimit.get('cp') ? 'red': ''">
                            <mat-autocomplete 
                                #auto="matAutocomplete" 
                                (optionSelected)="onChangeCP($event,'cp')">
                                    <mat-option  *ngFor="let option of options" [value]="option">{{option}}</mat-option>
                            </mat-autocomplete>
                        </div>
                        <em *ngIf="isInvalid('cp') && form5.get('cp')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('cp') && form5.get('cp')?.errors?.['invalidPostalCode']">
                            <p>* Code postal invalide</p>
                        </em>
                    </div>
        
                    <div class ="margin8px">
                        <label for="ville">Commune :<span class="obligatoire bold"> *</span></label>
                        <div>
                            <input type="text" name="ville" id="ville" class="input_obligatoire ville" formControlName="ville" readonly/>
                        </div>
                        <em *ngIf="isInvalid('ville') && form5.get('ville')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
                </div>
    
                <!-- <div class ="margin8px">
                    <label for="pays">Pays</label>
                    <div>
                        <select id="pays" name="pays" class="input_obligatoire" formControlName="pays">
                            <option *ngFor="let pays of liste_pays;let i = index" [value]="i+1">{{ pays }}</option>
                        </select>
                    </div>
                </div> -->
    
                <br>                                   
                
                <div class="bold d-flex">
                    <div>
                        <label class="cursor">
                            <input 
                                class="input_obligatoire cursor" 
                                type="checkbox" 
                                formControlName="isVisibleAdresseCorrespondance" 
                                (change)="showSameAddress()" 
                                #adresseCorrespondance>
                            &nbsp; &nbsp;
                            L’adresse de correspondance est différente de l’adresse du litige 
                        </label>
                    </div>
                    
                    &nbsp; &nbsp; &nbsp; 

                    <div>
                        <label class="cursor">
                            <input 
                                class="input_obligatoire cursor" 
                                type="checkbox" 
                                formControlName="isVisibleForeignAddress" 
                                (click)="showForeignAddress()" 
                                #adresseExterieure>
                            &nbsp; &nbsp;
                            L’adresse de correspondance est en dehors de la France
                        </label>
                    </div>
                </div>
                        
    
                <div class="corresContainer" *ngIf="isVisibleAdresseCorrespondance && !isVisibleForeignAddress">
                    <div  class ="margin8px">
                        <label for="adresse">Adresse de correspondance : <span class="obligatoire bold"> *</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="adresse_correspondance" 
                                id="adresse_correspondance" 
                                formControlName="adresse_correspondance"/>
                        </div>
                        <em *ngIf="isInvalid('adresse_correspondance') && form5.get('adresse_correspondance')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('adresse_correspondance') && form5.get('adresse_correspondance')?.errors?.['invalidAddress']">
                            <p>* L'adresse saisie n'est pas au bon format</p>
                        </em>
                    </div>
    
                    <div class ="margin8px">
                        <label for="code_postal2">Code postal de correspondance: <span class="obligatoire bold"> *</span></label>
                        <div>
                            <input 
                                type="text" 
                                matInput 
                                formControlName="cp_correspondance" 
                                [matAutocomplete]="auto_correspondance"
                                [maxlength]="inputLimit.get('cp_correspondance')"
                                [ngClass]="form5.get('cp_correspondance')?.value.length >= inputLimit.get('cp_correspondance') ? 'red': ''">
                            <mat-autocomplete 
                                #auto_correspondance="matAutocomplete" 
                                (optionSelected)="onChangeCP($event,'cp_correspondance')">
                                <mat-option  *ngFor="let option_ of options_correspondance" [value]="option_">{{option_}}</mat-option>
                            </mat-autocomplete>
                        </div>
                        <em *ngIf="isInvalid('cp_correspondance') && form5.get('cp_correspondance')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('cp_correspondance') && form5.get('cp_correspondance')?.errors?.['invalidPostalCode']">
                            <p>* Code postal invalide</p>
                        </em>
                    </div>
    
                    <div class ="margin8px">
                        <label for="ville2">Commune de correspondance: <span class="obligatoire bold"> *</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="ville2" 
                                id="ville2" 
                                class="ville_correspondance" 
                                formControlName="ville_correspondance" 
                                readonly/>
                        </div>
                        <em *ngIf="isInvalid('ville_correspondance') && form5.get('ville_correspondance')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
                    
                </div>  

                <div *ngIf="isVisibleForeignAddress">
                    <div class ="margin8px">
                        <label for="pays2">Pays</label>
                        <div>
                            <select id="pays_correspondance" name="pays_correspondance" class="input_obligatoire" formControlName="pays_correspondance">
                                <option *ngFor="let pays of liste_pays" [value]="pays.ID_PAYS">{{ pays.NOM_PAYS }}</option>
                            </select>
                        </div>
                    </div>
    
                    <div  class ="margin8px">	
                        <hr>		
                        <div>
                             <p class="h6">
                                Veuillez saisir toutes informations utiles concernant votre adresse (ville, code postal, compléments d'adresse, etc.)
                                &nbsp;
                                <span class="obligatoire bold"> *</span>
                            </p>

                             <div 
                                id="charNum2" 
                                class="charNum" 
                                [ngClass]="form5.get('complement_adresse_correspondance')?.value?.length >= inputLimit.get('complement_adresse') ? 'text-bold text-danger' : ''">
                                    {{form5.get('complement_adresse_correspondance')?.value?.length}} caractère@if(form5.get('complement_adresse_correspondance')?.value?.length > 1){s} / {{inputLimit.get('complement_adresse')}} caractères 
                            </div>

                            <textarea 
                                name="adresse_autre" 
                                id="textarea2" 
                                formControlName="complement_adresse_correspondance"
                                [maxlength]="inputLimit.get('complement_adresse')"
                                [ngClass]="form5.get('complement_adresse_correspondance')?.value?.length >= inputLimit.get('complement_adresse') ? 'bg-red': ''">
                            </textarea>

                            <em *ngIf="isInvalid('complement_adresse_correspondance') && form5.get('complement_adresse_correspondance')?.value?.length == 0"> 
                                <p>
                                    * Merci de remplir cette information
                                </p>
                            </em>
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <p class="text-bold h6">
                Exposé du litige : <span class="obligatoire bold" >*</span>
            </p>
    
            <p>
                Veuillez décrire de façon précise et synthétique le litige qui vous oppose au(x) service(s) d’eau et/ou d’assainissement 
            </p>
    
            <div id="charNum0" class="charNum" [ngClass]="form5.get('litige')?.value?.length >= inputLimit.get('litige') ? 'text-bold text-danger' : ''">
                {{form5.get('litige')?.value?.length}} caractère@if(form5.get('litige')?.value?.length > 1){s} / {{inputLimit.get('litige')}} caractères 
            </div>

            <textarea 
                placeholder="Exposé du litige..."
                id="textarea0" 
                formControlName="litige" 
                [maxLength]="inputLimit.get('litige')"
                [ngClass]="form5.get('litige')?.value?.length >= inputLimit.get('litige') ? 'bg-red': ''">
            </textarea>
    
            <em *ngIf="isInvalid('litige') && form5.get('litige')?.value?.length == 0"> 
                <p>
                    *
                    Merci de remplir cette information afin que nous puissions identifier quelle est votre demande exacte
                </p>
            </em>
    
            <br>
    
            <p class="text-bold h6">
                Qu’attendez-vous du service d’eau et/ou d’assainissement ? <span class="obligatoire bold">*</span>
            </p>
    
            <p>
                Veuillez indiquer ce que vous attendez exactement du (des) service(s) afin de mettre fin au litige 
            </p>

            <div id="charNum1" class="charNum" [ngClass]="form5.get('attentes')?.value?.length >= inputLimit.get('attentes') ? 'text-bold text-danger' : ''">
                {{form5.get('attentes')?.value?.length}} caractère@if(form5.get('attentes')?.value?.length > 1){s} / {{inputLimit.get('attentes')}} caractères 
            </div>
    
            <textarea 
                placeholder="Votre demande ..." 
                id="textarea1" 
                formControlName="attentes" 
                [maxLength]="inputLimit.get('attentes')"
                [ngClass]="form5.get('attentes')?.value?.length >= inputLimit.get('attentes') ? 'bg-red': ''">
                >
            </textarea>
    
            <em *ngIf="isInvalid('attentes') && form5.get('attentes')?.value?.length == 0">
                <p>
                    *
                    Merci de remplir cette information afin que nous puissions identifier quelle est votre demande exacte
                </p>
            </em>
            
            <p class="font-weight-bold">
                Vous pourrez rajouter tout autre document ou précision à la prochaine étape 
            </p>
    
        </div>
    </div>
    </div>

<div class="nav-buttons align-self-center" id="buttons">
    <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
    <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
</div>