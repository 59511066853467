import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './step-bar.component.html',
  styleUrl: './step-bar.component.css'
})
export class StepBarComponent 
{
  @Input() step!: number

  constructor(private router: Router)
  {}

  ngOnInit()
  {
    if (this.step == undefined)
    {
      let index = parseInt(this.router.url.slice(-1))
      this.step = index
    }
  }
}
