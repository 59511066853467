import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormDataService } from '../services/form-data/form-data.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MailService } from '../services/mail/mail.service';
import { confirmEmailAboValidator, confirmEmailValidator, sameEmailValidator } from '../custom_validators/email-validator';
import { saisine } from '../form/form.component';
import { HelpersService } from '../services/helpers/helpers.service';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CryptoService } from '../services/crypto/crypto.service';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';


@Component({
    selector: 'app-form4',
    standalone: true,
    templateUrl: './form4.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule]
})

export class Form4Component 
{
  form4!: FormGroup
  isVisible: boolean = false
  currentPage!: number
  info = faInfoCircle
  exclamation = faExclamation
  isEmailExist!:boolean
  hasDossier!:boolean
  isEmailAboExist!:boolean
  list_lien_abonne:{ID_TYPE_REPRESENTANT:number, TYPE_REPRESENTANT:string, ORDRE:number}[] = []
  list_mandat:number[] = [11, 16, 18] //Proche (membre de la famille, ami, ...) | Représentant légal (tutelle, curatelle, ...) | Propriétaire ou locataire
  isSubmitted:boolean = false
  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("text_limit", 300)
    .set("societe_organisme", 150)
    .set("nom", 100)
    .set("prenom", 100)
    .set("email", 200)
  )
  readonly emailPattern = Object.freeze("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$")
  isNewSaisine: boolean = false

  constructor
  (
    private formDataService: FormDataService,
    private navigationService: NavigationService,
    private helperService: HelpersService,
    private mailService: MailService,
    private cryptoService: CryptoService
  ) {}

  ngOnInit()
  {
    this.initForm()
    this.getDataLienAbo()
    this.currentPage = this.navigationService.getCurrentPage()

    this.form4.get('type_contrat')?.valueChanges.subscribe(data => {
      this.form4.get('info_eau_potable')?.setValue("")
      this.form4.get('info_assainissement_collectif')?.setValue("")
    })
  }

  initForm()
  {
    this.form4 = new FormGroup({
      civilite_abo : new FormControl(Number(saisine['_civilite_abo']), [Validators.required]),
      lien_abo : new FormControl(Number(saisine['_lien_abo'])),
      nom_abo : new FormControl(),
      prenom_abo : new FormControl(),
      email_abo : new FormControl("",[Validators.email, Validators.pattern(this.emailPattern)]),
      email_abo_confirm : new FormControl("",[Validators.email, Validators.pattern(this.emailPattern)]),
      societe_organisme_abo : new FormControl(),
      ref_dossier : new FormControl(saisine['_ref_dossier']),
      civilite : new FormControl(Number(saisine['_civilite']), [Validators.required]),
      nom : new FormControl(saisine['_nom'], [Validators.required, Validators.maxLength(this.inputLimit.get('nom'))]),
      prenom : new FormControl(saisine['_prenom'], [Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))]),
      email : new FormControl(saisine['_email'] ,[Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      email_confirm : new FormControl("", [Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      societe_organisme: new FormControl(saisine['_societe_organisme'], [Validators.maxLength(this.inputLimit.get('societe_organisme'))]),
      type_contrat: new FormControl(saisine['_type_contrat'], [Validators.required, this.notZeroValidator]),
      info_eau_potable: new FormControl(saisine['_info_eau_potable'], [Validators.maxLength(this.inputLimit.get('text_limit'))]),
      info_assainissement_collectif: new FormControl(saisine['_info_assainissement_collectif'], [Validators.maxLength(this.inputLimit.get('text_limit'))]),

      isAbo: new FormControl([Validators.requiredTrue])
    },
    {
      validators: [sameEmailValidator("email","email_abo"), confirmEmailValidator("email","email_confirm"), confirmEmailAboValidator("email_abo","email_abo_confirm")]
    })

    const isAbo = saisine['_lien_abo'] ? "1" : "0"
    this.form4.get('isAbo')?.setValue(isAbo)
    
    if(isAbo == "1") 
    {
      this.isVisible = true
      this.form4.get("isAbo")?.updateValueAndValidity()
      this.setAboInfo()
      this.setValidators()
      this.updateValueValidity()
    }
  }

  notZeroValidator(control: any) 
  {
    return control.value != 0 ? null : { notZero: true }
  }

  async getDataLienAbo()
  {
    (await this.helperService.getListLienAbonne()).subscribe(data => {
      for (const lien_abo of data) 
      {
        if(lien_abo.ORDRE != 2)
        {
          data.push({ID_TYPE_REPRESENTANT:-1, TYPE_REPRESENTANT:"--- Mandat / pouvoir obligatoire dans les cas ci-dessous : ---", ORDRE:1})
          break
        }
      }

      this.list_lien_abonne = data.sort((a, b) => b.ORDRE - a.ORDRE)
    })
  }

  showAboRep(event: Event)
  {
    this.isVisible = !this.isVisible

    if (event.target instanceof HTMLInputElement) 
    {
      const value = event.target?.value

      if( value == "1" )
      {
        this.setAboInfo()
        this.setValidators()
      }
      else
      {
        this.clearAboInfo()
      }
  
      this.updateValueValidity()
    }
  }

  setAboInfo()
  {
    this.form4.get('civilite_abo')?.setValue(saisine['_civilite_abo'])
    this.form4.get('lien_abo')?.setValue(saisine['_lien_abo'])
    this.form4.get('nom_abo')?.setValue(saisine['_nom_abo'])
    this.form4.get('prenom_abo')?.setValue(saisine['_prenom_abo'])
    this.form4.get('email_abo')?.setValue(saisine['_email_abo'])
    this.form4.get('societe_organisme_abo')?.setValue(saisine['_societe_organisme_abo'])
    this.form4.get('ref_dossier')?.setValue(saisine['_ref_dossier'])
  }

  updateValueValidity()
  {
    this.form4.get('civilite_abo')?.updateValueAndValidity()
    this.form4.get('lien_abo')?.updateValueAndValidity()
    this.form4.get('nom_abo')?.updateValueAndValidity()
    this.form4.get('prenom_abo')?.updateValueAndValidity()
    this.form4.get('email_abo')?.updateValueAndValidity()
    this.form4.get('email_abo_confirm')?.updateValueAndValidity()
  }

  clearAboInfo()
  {
    this.form4.get('civilite_abo')?.setValue(null)
    this.form4.get('lien_abo')?.setValue(0)
    this.form4.get('nom_abo')?.setValue("")
    this.form4.get('prenom_abo')?.setValue("")
    this.form4.get('email_abo')?.setValue("")
    this.form4.get('email_abo_confirm')?.setValue("")
    this.form4.get('societe_organisme_abo')?.setValue("")
    this.form4.get('ref_dossier')?.setValue("")

    saisine['_civilite_abo'] = 0
    saisine['_lien_abo'] = 0
    saisine['_nom_abo'] = ""
    saisine['_prenom_abo'] = ""
    saisine['_email_abo'] = ""
    saisine['_societe_organisme_abo'] = ""
    saisine['_ref_dossier'] = ""

    this.form4.get('civilite_abo')?.clearValidators()
    this.form4.get('lien_abo')?.clearValidators()
    this.form4.get('nom_abo')?.clearValidators()
    this.form4.get('prenom_abo')?.clearValidators()
    this.form4.get('email_abo')?.clearValidators()
    this.form4.get('email_abo_confirm')?.clearValidators()
  }

  setValidators()
  {
    this.form4.get('civilite_abo')?.setValidators([Validators.required])
    this.form4.get('lien_abo')?.setValidators([Validators.required])
    this.form4.get('nom_abo')?.setValidators([Validators.required, Validators.maxLength(this.inputLimit.get('nom'))])
    this.form4.get('prenom_abo')?.setValidators([Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))])
    this.form4.get('email_abo')?.setValidators([Validators.maxLength(this.inputLimit.get('email'))])
    this.form4.get('email_abo_confirm')?.setValidators([Validators.maxLength(this.inputLimit.get('email'))])
    this.form4.get('societe_organisme_abo')?.setValidators([Validators.maxLength(this.inputLimit.get('societe_organisme'))])
  }

  isInvalid(controlName: string)
  {
    const control = this.form4.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  getVisibility()
  {
    return this.isVisible
  }

  eachWordUpperCase(value:string, form_element:string)
  {
    this.form4.get(form_element)?.setValue
    (
      value
        ?.toLowerCase()
        ?.replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase())
    )
  }

  emptyInfoAC()
  {
    this.form4.get('info_assainissement_collectif')?.patchValue("")
  }

  emptyInfoEP()
  {
    this.form4.get('info_eau_potable')?.patchValue("")
  }

  @ScrollToTop()
  async saveFormAndNavigate(n:number)
  {
    if( n === -1 ) this.navigationService.redirect(n)

    this.isSubmitted = true
    
    if
    (
      this.form4.get("civilite")?.value == 0 
      || this.isVisible && this.form4.get("civilite_abo")?.value == 0
      || this.isVisible && !this.form4.get("lien_abo")?.value
    )
    {
      return this.form4.markAllAsTouched()
    }

    if (this.form4.invalid)
    {
      if (this.isVisible == false)
      {
        this.form4.controls['civilite'].markAsTouched()
        this.form4.controls['nom'].markAsTouched()
        this.form4.controls['prenom'].markAsTouched()
        this.form4.controls['email'].markAsTouched()
      }
      else this.form4.markAllAsTouched()
      return
    }
    else
    {
      // const dataFromStorage = sessionStorage.getItem("new") || ""
      // let isUserExists: boolean = false

      // if(dataFromStorage)
      // {
      //   this.cryptoService.decryptData(dataFromStorage).subscribe(data => {
      //     if(data)
      //     {
      //       const id_user = parseInt(data)
      //       this.helperService.checkIdUser(id_user).subscribe(data => {
      //         if(data.length !== 0 ) isUserExists = true
      //         else isUserExists = false
      //       })
      //     }
      //   })
      // }

      const email = this.form4.get("email")?.value

      this.mailService.checkEmail(email)
        .subscribe(data => {

          this.isEmailExist = data[0].emailExist

          // if(data[0].emailExist && !isUserExists)
          if(data[0].emailExist)
          {
            this.form4.get("email")?.setErrors({exists:true})
            return
          }

          saisine['_civilite_abo'] = Number(this.form4.value.civilite_abo)
          saisine['_lien_abo'] = parseInt(this.form4.value.lien_abo)
          saisine['_nom_abo'] = removeHTMLTags(this.form4.value.nom_abo)
          saisine['_prenom_abo'] = removeHTMLTags(this.form4.value.prenom_abo)
          saisine['_email_abo'] = removeHTMLTags(this.form4.value.email_abo)
          saisine['_societe_organisme_abo'] = removeHTMLTags(this.form4.value.societe_organisme_abo)
          saisine['_ref_dossier'] = removeHTMLTags(this.form4.value.ref_dossier)
          saisine['_civilite'] = Number(this.form4.value.civilite)
          saisine['_nom'] = removeHTMLTags(this.form4.value.nom)
          saisine['_prenom'] = removeHTMLTags(this.form4.value.prenom)
          saisine['_email'] = removeHTMLTags(this.form4.value.email)
          saisine['_societe_organisme'] = removeHTMLTags(this.form4.value.societe_organisme)
          saisine['_type_contrat'] = this.form4.value.type_contrat
          saisine['_info_eau_potable'] = removeHTMLTags(this.form4.value.info_eau_potable)
          saisine['_info_assainissement_collectif'] = removeHTMLTags(this.form4.value.info_assainissement_collectif)
          
          this.formDataService.saveFormData(saisine)
          this.navigationService.redirect(n)
        })
    }
  }
}

