import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-form1',
    standalone: true,
    templateUrl: './form1.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})

export class Form1Component 
{
  form1!: FormGroup
  currentPage!: number
  isErrorVisible: boolean = false
  info = faInfoCircle
  exclamation = faExclamation

  constructor
  (
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  )
  {
    window.parent.postMessage('scrollUp', environment.PARENT_URL)

    sessionStorage.clear()

    // this.activatedRoute.queryParams.subscribe((params:Params) => {
    //   console.log(params)
    //   if( params['new'] ) sessionStorage.setItem("new", params['new'])
    // })
    
    this.form1 = new FormGroup({
      exclusions : new FormControl(false,[Validators.requiredTrue]),
    })
  }

  ngOnInit()
  {
    this.currentPage = this.navigationService.getCurrentPage()
  }


  saveFormAndNavigate(n:number)
  {
    if (this.form1.invalid)
    {
      this.isErrorVisible = true
      return
    }
    else
    {
      this.isErrorVisible = false

      // const new_saisine_user_id = sessionStorage.getItem("new")
      // if(new_saisine_user_id)
      // {
      //   this.navigationService.redirectTo("formulaire_saisine/etape_2")
      // }
      // else
      // {
        this.navigationService.redirect(n)
      // }
    }
  }
}
