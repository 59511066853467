import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormDataService } from '../services/form-data/form-data.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PaysService } from '../services/pays/pays.service';
import { AddressValidator } from '../custom_validators/address-validator';
import { zipValidator } from '../custom_validators/zip-code-validator';
import { AutoCompleteService } from '../services/autocomplete/auto-complete.service';
import { saisine } from '../form/form.component';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';


@Component({
    selector: 'app-form5',
    standalone: true,
    templateUrl: './form5.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule, MatAutocompleteModule]
})
export class Form5Component 
{
  form5!: FormGroup
  currentPage!: number
  info = faInfoCircle
  exclamation = faExclamation
  options:any
  options_correspondance:any
  liste_pays: Array<{ID_PAYS:number, NOM_PAYS:string}> = []
  isSubmitted:boolean = false
  isEmailAboExist!:boolean
  isVisibleAdresseCorrespondance: boolean = false
  isVisibleForeignAddress: boolean = false
  emptyText:string = "Commune non trouvée"
  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("cp", 100)
    .set("cp_correspondance", 30)
    .set("litige", 1000)
    .set("attentes", 500)
    .set("complement_adresse", 300)
  )

  constructor
  (
    private formDataService: FormDataService,
    private navigationService: NavigationService,
    private autocompleteService: AutoCompleteService,
    private paysService: PaysService
  ) {}

  ngOnInit()
  {
    this.initForm()

    this.isVisibleAdresseCorrespondance = saisine['_adresse_correspondance'] ? true : false
    this.isVisibleForeignAddress = this.form5.get("isVisibleForeignAddress")?.value

    this.currentPage = this.navigationService.getCurrentPage()

    this.getPays()
    this.onChangeVisibleAdresseCorrespondance()

    this.form5.get("cp")?.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.autoComplete("cp",value)
      })

    this.form5.get("cp_correspondance")?.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.autoComplete("cp_correspondance",value)
      })
  }
  
  initForm()
  {
    this.form5 = new FormGroup({
      adresse : new FormControl(saisine['_adresse'] ,[Validators.required/*, AddressValidator()*/]),
      cp : new FormControl(saisine['_cp'] , [Validators.required, zipValidator(), Validators.maxLength(this.inputLimit.get('cp'))]),
      ville : new FormControl(saisine['_ville'] ,Validators.required),
      pays: new FormControl(1),

      adresse_correspondance : new FormControl(saisine['_adresse_correspondance']),
      cp_correspondance : new FormControl(saisine['_cp_correspondance']),
      ville_correspondance : new FormControl(saisine['_ville_correspondance']),
      pays_correspondance: new FormControl(saisine['_pays_correspondance']),
      complement_adresse_correspondance: new FormControl(saisine['_complement_adresse_correspondance']),
      
      litige : new FormControl(saisine['_litige'] ,[Validators.required, Validators.maxLength(this.inputLimit.get('litige'))]),
      attentes : new FormControl(saisine['_attentes'] ,[Validators.required, Validators.maxLength(this.inputLimit.get('attentes'))]),
   
      isVisibleAdresseCorrespondance : new FormControl(saisine['_adresse_correspondance'] ? true : false),
      isVisibleForeignAddress : new FormControl(saisine['_pays_correspondance'] != 0 ? true : false),
    })
  }

  async getPays()
  {
    (await this.paysService.getData()).subscribe(data => {
      data.push({ID_PAYS: 99, NOM_PAYS:"AUTRE"})
      this.liste_pays = data.filter((pays:{ID_PAYS:number, NOM_PAYS:string}) => pays.NOM_PAYS !== "FRANCE")
    })
  }

  async autoComplete(type_cp:string,value:string)
  {
    (await this.autocompleteService.autocomplete(type_cp, value)).subscribe(data => {   
      this.options = (data.length > 0 && type_cp === "cp")
        ? data.map(x => x.cp.concat(" - ", x.commune))
        : this.options = [this.emptyText]


      this.options_correspondance = (data.length > 0 && type_cp === "cp_correspondance")
        ? data.map(x => x.cp.concat(" - ", x.commune))
        : this.options_correspondance = [this.emptyText]
    })
  }

  onChangeVisibleAdresseCorrespondance()
  {
    this.form5.get('isVisibleAdresseCorrespondance')?.valueChanges.subscribe( value => {

      if( value )
      {
        this.form5.get('adresse_correspondance')?.setValidators([Validators.required/*, AddressValidator()*/])
        this.form5.get('cp_correspondance')?.setValidators([Validators.required, zipValidator(), Validators.maxLength(this.inputLimit.get('cp_correspondance'))])
        this.form5.get('ville_correspondance')?.setValidators([Validators.required]) 
      }
      else 
      {
        this.clearAdresseCorrespondanceInfo()
      }

      this.form5.get('adresse_correspondance')?.updateValueAndValidity()
      this.form5.get('cp_correspondance')?.updateValueAndValidity()
      this.form5.get('ville_correspondance')?.updateValueAndValidity() 
    })
  }


  setDataAdresseCorrespondance()
  {
    this.form5.get('adresse_correspondance')?.setValue(saisine['_adresse_correspondance'])
    this.form5.get('cp_correspondance')?.setValue(saisine['_cp_correspondance'])
    this.form5.get('ville_correspondance')?.setValue(saisine['_ville_correspondance'])
    this.form5.get('pays_correspondance')?.setValue(saisine['_pays_correspondance'])
  }

  clearAdresseInfo()
  {
    this.form5.get('adresse')?.setValue("")
    this.form5.get('cp')?.setValue("")
    this.form5.get('ville')?.setValue("")
    this.form5.get('pays')?.setValue(0)

    saisine['_adresse'] = ""
    saisine['_cp'] = ""
    saisine['_ville'] = ""
    saisine['_pays'] = 0
    
    this.form5.get('adresse')?.clearValidators()
    this.form5.get('cp')?.clearValidators()
    this.form5.get('ville')?.clearValidators()

    this.form5.get('adresse')?.updateValueAndValidity()
    this.form5.get('cp')?.updateValueAndValidity()
    this.form5.get('ville')?.updateValueAndValidity()
    this.form5.get('pays')?.updateValueAndValidity()
  }

  clearAdresseCorrespondanceInfo()
  {
    this.form5.get('adresse_correspondance')?.setValue("")
    this.form5.get('cp_correspondance')?.setValue("")
    this.form5.get('ville_correspondance')?.setValue("")

    saisine['_adresse_correspondance'] = ""
    saisine['_cp_correspondance'] = ""
    saisine['_ville_correspondance'] = ""
    
    this.form5.get('adresse_correspondance')?.clearValidators()
    this.form5.get('cp_correspondance')?.clearValidators()
    this.form5.get('ville_correspondance')?.clearValidators()

    this.form5.get('adresse_correspondance')?.updateValueAndValidity()
    this.form5.get('cp_correspondance')?.updateValueAndValidity()
    this.form5.get('ville_correspondance')?.updateValueAndValidity()
  }

  clearAdresseHorsFrance()
  {
    this.form5.get('pays_correspondance')?.setValue(0)
    this.form5.get('complement_adresse_correspondance')?.setValue("")
    
    saisine['_pays_correspondance'] = 0
    saisine['_complement_adresse_correspondance'] = ""
    
    this.form5.get('complement_adresse_correspondance')?.clearValidators()
    this.form5.get('complement_adresse_correspondance')?.updateValueAndValidity()
  }

  showSameAddress()
  {
    this.isVisibleAdresseCorrespondance = !this.isVisibleAdresseCorrespondance
  }

  showForeignAddress()
  {
    this.isVisibleForeignAddress = !this.isVisibleForeignAddress

    if( this.isVisibleForeignAddress )
    {
      this.setValidatorsCompelementAdresseHorsFrance()
      this.clearAdresseCorrespondanceInfo()
    }
    else
    {
      this.clearAdresseHorsFrance()
    }
  }

  setValidatorsCompelementAdresseHorsFrance()
  {
    this.form5.get('complement_adresse_correspondance')?.setValidators([Validators.required, Validators.maxLength(this.inputLimit.get('complement_adresse'))])
    this.form5.get('pays_correspondance')?.setValidators([Validators.required])
    this.form5.get('complement_adresse_correspondance')?.updateValueAndValidity()
    this.form5.get('pays_correspondance')?.updateValueAndValidity()
  }

  onChangeCP(event: MatAutocompleteSelectedEvent, type:string)
  {
    if(event.option.value == this.emptyText) return
    
    const cp = type == "cp" ? "cp" : "cp_correspondance"
    const ville = type == "cp" ? "ville" : "ville_correspondance"
    
    const [code_postal, ...commune] = event.option.value.split("-")
    this.form5.get(cp)?.setValue(code_postal.trim())
    this.form5.get(ville)?.setValue(commune.map((x:string) => x.trim()).join("-"))
  }

  isInvalid(controlName: string)
  {
    const control = this.form5.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) this.navigationService.redirect(n)

    if (this.form5.invalid) this.form5.markAllAsTouched()
    else
    {
      const {isVisibleAdresseCorrespondance, ...form } = this.form5.value

      saisine['_adresse'] = removeHTMLTags(form.adresse)
      saisine['_cp'] = removeHTMLTags(form.cp)
      saisine['_ville'] = removeHTMLTags(form.ville)
      saisine['_pays'] = form.pays

      saisine['_adresse_correspondance'] = removeHTMLTags(form.adresse_correspondance)
      saisine['_cp_correspondance'] = removeHTMLTags(form.cp_correspondance)
      saisine['_ville_correspondance'] = removeHTMLTags(form.ville_correspondance)
      saisine['_pays_correspondance'] = form.pays_correspondance
      saisine['_complement_adresse_correspondance'] = removeHTMLTags(form.complement_adresse_correspondance)

      saisine['_litige'] = removeHTMLTags(form.litige)
      saisine['_attentes'] = removeHTMLTags(form.attentes)

      this.formDataService.saveFormData(saisine)
      this.navigationService.redirect(n)
    }
  }
}
