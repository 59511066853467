<h1 class="form-title">Saisir le Médiateur de l'eau</h1>

<app-step-bar></app-step-bar>

<div class="mt-5 offset-2">
    <h2>Page non trouvé</h2>
    <span>Veuillez cliquer sur le bouton ci-dessous pour revenir au formulaire de saisine</span>
    &nbsp;
    ( <small>Vous reviendrez à l'étape 1 du formulaire</small> )
    <div>
        <button class="btn btn-warning font-weight-bold offset-3 mt-5 col-2" type="button" (click)="returnToStepOne()">Aller vers le formulaire</button>
    </div>
</div>
