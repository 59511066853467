import { AbstractControl, ValidatorFn } from '@angular/forms';

export function fileValidator(): ValidatorFn 
{
  return (control: AbstractControl): { [key: string]: any } | null => {
    const file = control.value as File;
    if (file) return null // Le fichier est sélectionné, la validation réussit
    return { 'required': true } // Aucun fichier sélectionné, la validation échoue
  };
}
