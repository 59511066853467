import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async getData():Promise<Observable<{ID_PAYS:number, NOM_PAYS:string}[]>>
  {
    const HOST = environment.BASE_URL

    const url  = `${HOST}/pays/`
    return this.httpClient.get<{ID_PAYS:number, NOM_PAYS:string}[]>(url)
  }
}
