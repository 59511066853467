<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 3 </h4>
        <hr>
        <h4>
            Préparez vos documents. Pensez à les scanner, ils vous seront demandés à l'étape 6
        </h4>
        <strong>
            Rappel : le Médiateur est indépendant des services d’eau et d’assainissement, il ne dispose donc d’aucun document concernant le litige
        </strong>
    </div>
</div>

<div class="tab tab3 d-flex flex-column" [formGroup]="form3">
    <div class="content">
        <div class="content-left">
    
            <p>Documents obligatoires <u class="text-bold">à transmettre dès la saisine</u> :</p>
    
            <p>
                <fa-icon [icon]="file" size="lg" class="fa-icon-file"></fa-icon> 
                Copie <u>recto-verso</u> des factures d’eau et/ou d’assainissement (ou du devis) afin d'identifier le(s) service(s)
            </p>
    
            <p> 
                <fa-icon [icon]="file" size="lg" class="fa-icon-file"></fa-icon> 
                Copie des réclamations écrites adressées aux services d’eau et/ou d’assainissement
            </p>
    
            <p>
                <fa-icon [icon]="file" size="lg" class="fa-icon-file"></fa-icon>
                Copie des éventuelles réponses des services
            </p>
    
            <p>
                <fa-icon [icon]="file" size="lg" class="fa-icon-file"></fa-icon> 
                En cas de fuite, indiquer son emplacement précis et transmettre la copie de la facture de réparation le cas échéant
            </p>
    
            <p>
                <fa-icon [icon]="file" size="lg" class="fa-icon-file"></fa-icon> 
                Tous autres documents que vous jugez utiles
            </p>

            <br>
            
            <p>
                <b>Seuls les formats pdf, jpg, jpeg et png ne dépassant pas 10 Mo sont acceptés</b>
            </p>
    
            <hr />
    
            <label class="checkbox-container bold" for="input5">
                Je peux fournir les documents ci-dessus
                <br>
                <input type="checkbox" id="input5" name="input5" formControlName="documents">
                <em *ngIf="isErrorVisible">
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon> 
                        Nous avons besoin de ces documents pour traiter votre dossier.
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>

            <p class="bold mt-4">A défaut, nous vous rappelons que vous pouvez saisir le Médiateur par courrier</p>
        </div>
    </div>
    </div>

<div class="nav-buttons align-self-center" id="buttons">
    <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
    <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
</div>