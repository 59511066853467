<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 4 </h4>
        <hr>

        <h4>
            Identifiez-vous
        </h4>

        <p>
            Toutes les notifications concernant l’avancement du dossier seront envoyées à l’adresse mail renseignée. Celle-ci servira également d’identifiant vous permettant d’accéder à votre espace personnel.
        </p>
        <p>
            Vous disposez à tout moment d’un droit de rectification et/ou de suppression de vos données personnelles
        </p>
    </div>
</div>

<div class="tab tab4 form-group row" [formGroup]="form4">
    <div class="content col-12">
        <div class="content-left order-1 col-6">
            
            <h4>Je suis : </h4>

            <br>

            <div style="display:flex">
                <label class="checkbox-container bold">
                    <input type="radio" formControlName="isAbo" id="profil_abo_repr0" value="0" (change)="showAboRep($event)" checked>&nbsp;l'abonné(e) concerné(e) par le litige
                    <span class="checkmark"></span>
                </label>
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
                <label class="checkbox-container bold">
                    <input type="radio" formControlName="isAbo" id="profil_abo_repr1" value="1" (change)="showAboRep($event)" >&nbsp;le représentant de l'abonné(e)
                    <span class="checkmark"></span>
                </label>
            </div>

            <div>
                <p><span class="obligatoire bold">*</span> Champs obligatoires</p>
            </div>

            <p class="h4 red" *ngIf="isVisible">Seul le représentant recevra des messages de la Médiation de l'eau</p>
    
            <hr>
    
            <div>
                <h5 class="font-weight-bold" *ngIf="isVisible">Informations du représentant</h5>

                <div class="margin8px">
                    <label for="societe">Société ou Organisme :</label>
                    <div>
                        <input 
                            class="form-control" 
                            type="text" 
                            name="societe" 
                            id="societe" 
                            formControlName="societe_organisme" 
                            oninput="this.value = this.value.toUpperCase()" 
                            [maxLength]="inputLimit.get('societe_organisme')"
                            [ngClass]="form4.get('societe_organisme')?.value.length >= inputLimit.get('societe_organisme') ? 'red': ''"/>
                    </div>
                </div>

                <div class="margin8px">
                    <label for="civilite">Civilité : <span class="obligatoire bold">*</span></label>
                    <div>
                        <select name="civilite" id="civilite" class="input_obligatoire" formControlName="civilite">
                            <option value="2">Madame</option>
                            <option value="1">Monsieur</option>
                            <option value="6">Madame, Monsieur</option>
                            <option value="5">Maître</option>
                            <option value="7">Mesdames</option>
                            <option value="8">Messieurs</option>
                        </select>
                        <em *ngIf="isInvalid('civilite') || (isSubmitted && form4.get('civilite')?.value == 0)">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
                </div>
    
                <div class="margin8px">
                    <label for="nom">Nom :  <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="text" 
                            name="nom" 
                            id="nom" 
                            class="input_obligatoire form-control" 
                            formControlName="nom" 
                            oninput="this.value = this.value.toUpperCase()"
                            [maxLength]="inputLimit.get('nom')" 
                            [ngClass]="form4.get('nom')?.value.length >= inputLimit.get('nom') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isInvalid('nom') && form4.get('nom')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
    
                <div class="margin8px">
                    <label for="prenom">Prénom :  <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="text" 
                            name="prenom" 
                            id="prenom" 
                            class="input_obligatoire form-control" 
                            formControlName="prenom" 
                            [maxLength]="inputLimit.get('prenom')"
                            [ngClass]="form4.get('prenom')?.value.length >= inputLimit.get('prenom') ? 'red': ''" 
                            (keyup)="eachWordUpperCase(form4.get('prenom')?.value, 'prenom')"/>
                    </div>
                    <em *ngIf="isInvalid('prenom') && form4.get('prenom')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
                
                <div class="margin8px">
                    <label for="email">Email :  <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="email" 
                            name="email" 
                            id="email" 
                            class="input_obligatoire form-control input_mail" 
                            formControlName="email" 
                            [maxLength]="inputLimit.get('email')"
                            [ngClass]="form4.get('email')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isInvalid('email') && form4.get('email')?.value?.length == 0">
                        <p>* Champ obligatoire</p>
                    </em>
                    <em *ngIf="isSubmitted && isInvalid('email') && form4.get('email')?.errors?.['pattern']">
                        <p>* Adresse Email non valide</p>
                    </em>
                    <em *ngIf="isSubmitted && isVisible && form4.hasError('sameEmailMismatch')">
                        <p>* Votre adresse email et celle de votre représentant doivent être différentes</p>
                    </em>
                    <em *ngIf="isEmailExist">
                        <p>
                            * Cette adresse mail est déjà liée à un espace abonné. Veuillez vous connecter à cet espace via ce lien 
                            <a href="https://preprod.abonnes.mediation-eau.fr/" target="_blank">abonnes.mediation-eau.fr</a> 
                        </p>
                    </em>
                </div>

                <div class="margin8px">
                    <label for="email">Confirmer l'adresse email :  <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="email" 
                            name="email_confirm" 
                            id="email_confirm" 
                            class="input_obligatoire form-control input_mail" 
                            formControlName="email_confirm" 
                            [maxLength]="inputLimit.get('email')"
                            [ngClass]="form4.get('email')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isInvalid('email_confirm') && form4.get('email_confirm')?.value?.length == 0">
                        <p>* Champ obligatoire</p>
                    </em>
                    <em *ngIf="isSubmitted && isInvalid('email_confirm') && form4.get('email_confirm')?.errors?.['pattern']">
                        <p>* Adresse Email non valide</p>
                    </em>
                    <em *ngIf="isSubmitted && form4.hasError('emailMismatch')">
                        <p>* Les adresses email ne sont pas identiques</p>
                    </em>
                </div>
    
            </div>
    
            <br>
            <br>

            <div class="type_rep max-width600px" *ngIf="isVisible">                
                <h5 class="font-weight-bold">Informations de l’abonné(e)</h5>

                <div class="form-group type_rep margin8px">
                    <label for="id_type_rep">Lien du représentant avec l'abonné(e) : <span class="obligatoire bold">*</span></label>
                    <div>
                        <select class="cond_obligatoire input_obligatoire_abo" name="id_type_rep" id="id_type_rep" (change)="'form-control cond_obligatoire input_obligatoire_abo'" formControlName="lien_abo">
                            <option 
                                [value]="item.ID_TYPE_REPRESENTANT" 
                                *ngFor="let item of list_lien_abonne"
                                [ngClass]="item.ORDRE == 1 ? 'red': ''"
                                [disabled]="item.ID_TYPE_REPRESENTANT == -1">
                                {{ item.TYPE_REPRESENTANT }}
                            </option>
                        </select>
                    </div>
                    <em *ngIf="isInvalid('lien_abo') || ( isSubmitted && form4.get('lien_abo')?.value == 0)">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>

                <div class="margin8px">
                    <label for="societe_abo">Société ou Organisme de l'abonné(e) :</label>
                    <div>
                        <input 
                            class="form-control" 
                            type="text" 
                            name="societe_abo" 
                            id="societe_abo" 
                            formControlName="societe_organisme_abo" 
                            oninput="this.value = this.value.toUpperCase()" 
                            [maxLength]="inputLimit.get('societe_organisme')"
                            [ngClass]="form4.get('societe_organisme_abo')?.value.length >= inputLimit.get('societe_organisme') ? 'red': ''"/>
                    </div>
                </div>

                <div class="margin8px">
                    <label for="civilite">Civilité : <span class="obligatoire bold">*</span></label>
                    <div>
                        <select name="civilite_abo" id="civilite_abo" class="input_obligatoire" formControlName="civilite_abo">
                            <option value="2">Madame</option>
                            <option value="1">Monsieur</option>
                            <option value="6">Madame, Monsieur</option>
                            <option value="5">Maître</option>
                            <option value="7">Mesdames</option>
                            <option value="8">Messieurs</option>
                        </select>
                        <em *ngIf="isInvalid('civilite_abo') || (isSubmitted && form4.get('civilite_abo')?.value == 0)">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
                </div>
    
                <div class="margin8px">
                    <label for="nom_abo">Nom de l'abonné(e) : <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="text" 
                            name="nom_abo" 
                            id="nom_abo" 
                            class="input_obligatoire_abo form-control" 
                            formControlName="nom_abo" 
                            oninput="this.value = this.value.toUpperCase()" 
                            [maxLength]="inputLimit.get('nom')"
                            [ngClass]="form4.get('nom_abo')?.value.length >= inputLimit.get('nom') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isInvalid('nom_abo') && form4.get('nom_abo')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
    
                <div class="margin8px">
                    <label for="prenom_abo">Prénom de l'abonné(e) : <span class="obligatoire bold">*</span></label>
                    <div>
                        <input 
                            type="text" 
                            name="prenom_abo" 
                            id="prenom_abo" 
                            class="input_obligatoire_abo form-control" 
                            formControlName="prenom_abo" 
                            (input)="eachWordUpperCase(form4.get('prenom_abo')?.value, 'prenom_abo')" 
                            [maxLength]="inputLimit.get('prenom')"
                            [ngClass]="form4.get('prenom_abo')?.value.length >= inputLimit.get('prenom') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isInvalid('prenom_abo') && form4.get('prenom_abo')?.errors">
                        <p>* Champ obligatoire</p>
                    </em>
                </div>
    
                <div class="margin8px">
                    <label for="email_abo">Email de l'abonné(e) : </label>
                    <div>
                        <input 
                            type="email" 
                            name="email_abo" 
                            id="email_abo" 
                            class="input_mail form-control" 
                            formControlName="email_abo" 
                            [maxLength]="inputLimit.get('email')"
                            [ngClass]="form4.get('email_abo')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isSubmitted && form4.get('email_abo')?.errors?.['pattern']">
                        <p>* Adresse Email non valide</p>
                    </em>
                    <em *ngIf="isSubmitted && isVisible && form4.hasError('sameEmailMismatch')">
                        <p>* Votre adresse email et celle de votre représentant doivent être différentes</p>
                    </em>
                    <!-- <em *ngIf="isEmailAboExist">
                        <p>
                            * Cette adresse mail est déjà liée à un espace abonné. Veuillez vous connecter à cet espace via ce lien 
                            <a href="https://preprod.abonnes.mediation-eau.fr/" target="_blank">abonnes.mediation-eau.fr</a> 
                        </p>
                    </em> -->
                </div>

                <div class="margin8px" *ngIf="form4.get('email_abo')?.value?.length > 0">
                    <label for="email_abo">Confirmer l'adresse email de l'abonné(e) : </label>
                    <div>
                        <input 
                            type="email" 
                            name="email_abo_confirm" 
                            id="email_abo_confirm" 
                            class="input_mail form-control" 
                            formControlName="email_abo_confirm" 
                            [maxLength]="inputLimit.get('email')"
                            [ngClass]="form4.get('email_abo_confirm')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                    </div>
                    <em *ngIf="isSubmitted && form4.get('email_abo_confirm')?.errors?.['pattern']">
                        <p>* Adresse Email non valide</p>
                    </em>
                    <em *ngIf="isSubmitted && form4.hasError('emailAboMismatch')">
                        <p>* Les adresses email ne sont pas identiques</p>
                    </em>
                </div>
    
    
                <div class="margin8px">
                    <label for="refDossier">Votre référence dossier (si vous en possédez une) :</label>
                    <div>
                        <input class="form-control" type="text" id="refDossier" name="refDossier" formControlName="ref_dossier"/>
                    </div>
                </div>
            </div>

            <br>
    
        </div>

        <div class="col-6 order-2">

            <div style="margin-top:4rem">
                <br>
            </div>
            
            <br>

            <h5>Indiquer dans quel cas l’abonné(e) se trouve :
                <br>
                (Cette information est reprise sur la facture d’eau et/ou d’assainissement)
            </h5>

            <br>

            <div class="form-check mb-3">
                <input class="form-check-input" type="radio" id="flexRadioDefault1" value=1 formControlName="type_contrat">
                <label class="form-check-label h6" for="flexRadioDefault1">
                    L’abonné(e) est facturé(e) pour l’eau potable et l’assainissement collectif sur une facture unique
                </label>
            </div>

            <div class="form-check my-3">
                <input class="form-check-input" type="radio" id="flexRadioDefault2" value=5 formControlName="type_contrat">
                <label class="form-check-label h6" for="flexRadioDefault2">
                    L’abonné(e) est facturé(e) pour l’eau potable et l’assainissement collectif sur deux factures distinctes
                </label>
            </div>

            <div class="form-check my-3">
                <input class="form-check-input" type="radio" id="flexRadioDefault3" value=2 formControlName="type_contrat" (click)="emptyInfoAC()">
                <label class="form-check-label h6" for="flexRadioDefault3">
                    L’abonné(e) est facturé(e) pour l’eau potable uniquement
                </label>
            </div>

            <div class="form-check my-3">
                <input class="form-check-input" type="radio" id="flexRadioDefault4" value=3 formControlName="type_contrat" (click)="emptyInfoEP()">
                <label class="form-check-label h6" for="flexRadioDefault4">
                    L’abonné(e) est facturé(e) pour l’assainissement collectif uniquement
                </label>
            </div>

            <div class="form-check my-3">
                <input class="form-check-input" type="radio" id="flexRadioDefault5" value=4 formControlName="type_contrat">
                <label class="form-check-label h6" for="flexRadioDefault5">
                    Ne dispose pas de l’information
                </label>
            </div>

            <em *ngIf="(isSubmitted && form4.get('type_contrat')?.value == '0')">
                <p>* Vous devez sélectionner une des options ci-dessus</p>
            </em>

            <div *ngIf="form4.get('type_contrat')?.value == '2'">
                <br>
                <h5>Si l’abonné(e) n’est facturé(e) que pour l’eau potable, nous préciser pour quelle raison (fosse septique…) :</h5>
                <span 
                    class="font-weight-bold" 
                    [ngClass]="form4.get('info_eau_potable')?.value.length >= inputLimit.get('text_limit') ? 'red': 'blue'">
                        {{form4.get('info_eau_potable')?.value.length}} caractère@if(form4.get('info_eau_potable')?.value.length > 1){s} / {{inputLimit.get('text_limit')}} caractères
                </span>
                <textarea 
                    [maxLength]="inputLimit.get('text_limit')" 
                    formControlName="info_eau_potable" 
                    [ngClass]="form4.get('info_eau_potable')?.value.length >= inputLimit.get('text_limit') ? 'bg-red':''">
                </textarea>
            </div>

            <div *ngIf="form4.get('type_contrat')?.value == '3'">
                <br>
                <h5>Si l’abonné(e) n’est facturé(e) que pour l’assainissement collectif, nous préciser pour quelle raison (puits, forage, …) :</h5>
                <span 
                    class="font-weight-bold" 
                    [ngClass]="form4.get('info_assainissement_collectif')?.value.length >= inputLimit.get('text_limit') ? 'red': 'blue'">
                        {{form4.get('info_assainissement_collectif')?.value.length}} caractère@if(form4.get('info_assainissement_collectif')?.value.length > 1){s} / {{inputLimit.get('text_limit')}} caractères
                </span>
                <textarea 
                    [maxLength]="inputLimit.get('text_limit')" 
                    formControlName="info_assainissement_collectif" 
                    [ngClass]="form4.get('info_assainissement_collectif')?.value.length >= inputLimit.get('text_limit') ? 'bg-red':''">
                </textarea>
            </div>
        </div>
    </div>
</div>

<div class="nav-buttons align-self-center" id="buttons">
    <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
    <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
</div>
