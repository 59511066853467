<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 2 </h4>
        <hr>

        <h4>
            La recevabilité de la saisine : les conditions
        </h4>

        <p>
            Assurez-vous que vous remplissez bien toutes les conditions ci-dessous. A défaut, le dossier ne sera pas recevable pour une médiation
        </p>
    </div>
</div>

<div class="tab tab2 d-flex flex-column" [formGroup]="form2">
    <div class="content">
        <div class="content-left">
    
            <label class="checkbox-container" for="input1">
                Je suis en litige avec mon service d’eau et/ou d’assainissement auprès duquel je suis directement abonné / 
                la personne que je représente est en litige avec son service et est abonnée auprès de ce dernier
                <br>
                <input type="checkbox" id="input1" name="input1" formControlName="condition_1">
                <em *ngIf="isInvalid('condition_1') && form2.get('condition_1')?.errors">
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon> 
                        Condition obligatoire
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>
    
            <label class="checkbox-container" for="input2">
                Une <u>réclamation écrite</u> a été adressée à votre service il y a moins d’un an
                <br>
                <input type="checkbox" id="input2" name="input2" formControlName="condition_2">
                <em *ngIf="isInvalid('condition_2') && form2.get('condition_2')?.errors"> 
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon>
                        Vous devez adresser une réclamation écrite à votre service selon les conditions indiquées par celui-ci (voir le site internet, le règlement de service, le contrat d’abonnement, la facture, etc.)
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>
    
            <label class="checkbox-container" for="input3">
                Aucune réponse n’a été reçue dans un délai de deux mois ou bien la réponse obtenue n’est pas satisfaisante
                <br>
                <input type="checkbox" id="input3" name="input3" formControlName="condition_3">
                <em *ngIf="isInvalid('condition_3') && form2.get('condition_3')?.errors">
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon>
                        Condition obligatoire
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>
    
            <label class="checkbox-container" for="input4">
                Aucun recours en justice ou à un autre médiateur n’a été engagé pour le litige concerné 
                <br>
                <input type="checkbox" id="input4" name="input4" formControlName="condition_4">
                <em *ngIf="isInvalid('condition_4') && form2.get('condition_4')?.errors">
                    <p>
                        <fa-icon [icon]="exclamation"></fa-icon>
                        Le Médiateur ne peut pas examiner un litige précédemment étudié ou en cours d'examen par un autre médiateur ou un tribunal (article L.612-2 du code de la consommation)
                    </p>
                </em>
                <span class="checkmark"></span>
            </label>

            <hr>
        </div>
    </div>
    </div>

<div class="nav-buttons align-self-center" id="buttons">
    <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>
    <input type="button" value="Je valide et passe à l'étape suivante" id="submitBtn" (click)="saveFormAndNavigate(1)"/>
</div>
